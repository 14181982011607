const ShouldUpdateTicket = (ticket, user, searchParam, showAll, selectedQueueIds) => {		
	if (searchParam) return false;
	if (showAll) return true;
	if (ticket.status === "open" && ticket.createByMe) return true;
	const isTicketUnassigned = !ticket.userId;
	const isTicketAssignedToCurrentUser = ticket.userId === user?.id;
	if (!isTicketUnassigned && !isTicketAssignedToCurrentUser) return false;	
	const isTicketInSelectedQueues = selectedQueueIds.includes(ticket.queueId);
	return isTicketInSelectedQueues;
}
  
export default ShouldUpdateTicket;