import React, { useEffect, useState } from "react"

import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography";
import { i18n } from "../../translate/i18n";
import Chart from "./Chart"

import api from "../../services/api"
import { Table, 
	TableBody, 
	TableCell, 
	TableHead, 
	TableRow } from "@material-ui/core"
import ContactRanking from "../../components/ContactRanking"

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 240,
		backgroundColor: theme.palette.background.inverse
	},
	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 120,
		backgroundColor: theme.palette.background.inverse
	},
	customFixedHeightPaperLg: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "100%",
	}	
}))

const Dashboard = () => {
	const classes = useStyles()
	const [metrics, setMetrics] = useState({});	
	const fetchMetrics = async () => {
		const { data } = await api.get("/dashboard/metrics");
		setMetrics(data);
	}
	useEffect(() => {
		fetchMetrics();
		// eslint-disable-next-line
	}, []);
	return (
		<div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container spacing={3}>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{i18n.t("dashboard.messages.inAttendance.title")}
							</Typography>
							<Grid item>
								<Typography component="h1" variant="h4">
									{metrics.inAttendance ?? 0}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{i18n.t("dashboard.messages.waiting.title")}
							</Typography>
							<Grid item>
								<Typography component="h1" variant="h4">
									{metrics.waiting ?? 0}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{i18n.t("dashboard.messages.closed.title")}
							</Typography>
							<Grid item>
								<Typography component="h1" variant="h4">
									{metrics.closed ?? 0}
								</Typography>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{i18n.t("dashboard.messages.average.perDay")}
							</Typography>
							<Grid item>
								<Typography component="h1" variant="h4">
									{metrics.averageTicket ?? 0}
								</Typography>
							</Grid>
						</Paper>						
					</Grid>		
					<Grid item xs={4}>
						<Paper className={classes.customFixedHeightPaper} style={{ overflow: "hidden" }}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{i18n.t("dashboard.messages.average.perMessage")}
							</Typography>
							<Grid item>
								<Typography component="h1" variant="h4">
									{metrics.averageMessages ?? 0}
								</Typography>
							</Grid>
						</Paper>
					</Grid>			
					<Grid item xs={12}>
						<Paper className={classes.fixedHeightPaper}>
							<Chart chartData={metrics.today} />
						</Paper>
					</Grid>
					<Grid item xs={6}>
						<Paper className={classes.customFixedHeightPaperLg}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{i18n.t("dashboard.messages.rankingPerUser.title")}
							</Typography>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>{i18n.t("mainDrawer.listItems.tickets")}</TableCell>
										<TableCell>{i18n.t("contacts.table.name")}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<>
										{metrics.userRanking && metrics.userRanking.map((metric, index) => (
											<TableRow key={index}>
												<TableCell>
													{metric.amount}
												</TableCell>
												<TableCell>
													{metric.name}
												</TableCell>
											</TableRow>
										))}
									</>
								</TableBody>
							</Table>
						</Paper>
					</Grid>
					<Grid item xs={6}>
						<ContactRanking />			
					</Grid>
				</Grid>
				<Grid container spacing={3}>
					<Grid item xs={6}>
						<Paper className={classes.fixedHeightPaper}>
							<Typography component="h3" variant="h6" color="primary" paragraph>
								{i18n.t("dashboard.messages.rankingPerWhatsapp.title")}
							</Typography>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>{i18n.t("mainDrawer.listItems.tickets")}</TableCell>
										<TableCell>{i18n.t("contacts.table.name")}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<>
										{metrics.whatsappRanking && metrics.whatsappRanking.map((metric, index) => (
											<TableRow key={index}>
												<TableCell>
													{metric.amount}
												</TableCell>
												<TableCell>
													{metric.name}
												</TableCell>
											</TableRow>
										))}
									</>
								</TableBody>
							</Table>
						</Paper>
					</Grid>
				</Grid>		
			</Container>
		</div>
	)
}

export default Dashboard