import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { ptBR } from "@material-ui/core/locale";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "./context/Theme/ThemeContext";

const App = () => {
  const [locale, setLocale] = useState();  
  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale = i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);
    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);
  return (
    <ThemeProvider locale={locale}>
      <CssBaseline>
        <Routes />
	    </CssBaseline>
    </ThemeProvider>
  );
};

export default App;