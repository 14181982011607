import React from "react";
import { useTheme } from "@material-ui/core/styles";
import {
	BarChart,
	CartesianGrid,
	Bar,
	XAxis,
	YAxis,
	Label,
	ResponsiveContainer,
} from "recharts";

import { i18n } from "../../translate/i18n";

import Title from "./Title";

const Chart = (props) => {
	const theme = useTheme();
	const chartData = props.chartData;	
	const amountToday = chartData ? chartData.map(today => today.amount).reduce((a, b) => a + b) : 0;
	return (
		<React.Fragment>
			<Title>{`${i18n.t("dashboard.charts.perDay.title")}${amountToday}`}</Title>
			<ResponsiveContainer>
				<BarChart
					data={chartData}
					barSize={40}
					width={730}
					height={250}
					margin={{
						top: 16,
						right: 16,
						bottom: 0,
						left: 24,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="time" stroke={theme.palette.text.secondary} />
					<YAxis
						type="number"
						allowDecimals={false}
						stroke={theme.palette.text.secondary}
					>
						<Label
							angle={270}
							position="left"
							style={{ textAnchor: "middle", fill: theme.palette.text.primary }}
						>
							{i18n.t("dashboard.charts.perDay.subtitle")}
						</Label>
					</YAxis>
					<Bar dataKey="amount" fill={theme.palette.primary.main} />
				</BarChart>
			</ResponsiveContainer>
		</React.Fragment>
	);
};

export default Chart;
