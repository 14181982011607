import { useEffect, useState } from "react";

const useTheme = () => {
    const [isDarkMode, setIsDarkMode] = useState(false);
    const handleChangeTheme = () => {        
        setIsDarkMode(!isDarkMode);
    };
    useEffect(() => {
        const checkIsDarkSchemePreferred = () => window?.matchMedia?.('(prefers-color-scheme:dark)')?.matches ?? false;
        setIsDarkMode(checkIsDarkSchemePreferred());
    }, []);
    return { isDarkMode, handleChangeTheme };
};


export default useTheme;